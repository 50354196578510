import { template as template_af7949779d9f444cb759889025fe7b3c } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import TranslationWithArgs from '@qonto/ui-kit/components/translation-with-args';
import { t } from 'ember-intl';
import LinkToFlow from 'qonto/components/link-to-flow';
import track from 'qonto/helpers/track';
function defaultTo(a, b) {
    return b ?? a;
}
const LinkToManagementKycFlow = template_af7949779d9f444cb759889025fe7b3c(`
  <LinkToFlow
    @name='management-kyc'
    @stepId={{@stepId}}
    ...attributes
    data-test-link
    data-test-management-kyc-link
    {{on 'click' (track 'submit_kyc_information_start')}}
  >
    {{@text}}
  </LinkToFlow>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const KycNotSubmittedDisclaimer = template_af7949779d9f444cb759889025fe7b3c(`
  <span>
    <TranslationWithArgs
      @key={{@textTranslationKey}}
      @text={{t @textTranslationKey kycFlowLink=(t @linkTextTranslationKey)}}
      as |linkText|
    >
      {{#if @personalInfoProvided}}
        <LinkTo
          @route='kyc.start'
          data-test-link
          data-test-management-kyc-link
          ...attributes
        >{{linkText}}</LinkTo>
      {{else}}
        <LinkToManagementKycFlow
          @stepId={{defaultTo 'user-info' @stepId}}
          @text={{linkText}}
          ...attributes
        />
      {{/if}}
    </TranslationWithArgs>
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default KycNotSubmittedDisclaimer;
