export default {
  "item-status": "cJj",
  "item-date": "cJW",
  "item-amount": "cJu body-1",
  "item-amount-credit": "csS",
  "row": "csc",
  "icon": "csq",
  "disabled": "csZ",
  "icon-container": "csR",
  "text-container": "csQ",
  "text-row": "cse",
  "item-details": "csB",
  "counterparty-name": "csE",
  "column": "csd",
  "item-amount-disabled": "csI",
  "attachment-tooltip": "cst",
  "bank-account-avatar": "csM"
};
