export default {
  "account-container": "csY",
  "badge": "csh",
  "subtitle": "csf title-4",
  "accounts-filter": "csK",
  "account-filter-label": "csG",
  "status": "csr",
  "account-info": "csb",
  "balance": "csy",
  "revealed-balance": "csU",
  "hidden-balance": "csj",
  "mr-12": "csW",
  "disabled-selector": "csu"
};
