export default {
  "header-cell": "cDy",
  "checkbox-cell": "cDU",
  "title-col": "cDj",
  "internal-note-col": "cDW",
  "unit-col": "cDu",
  "price-col": "cVS",
  "header-content": "cVc caption-bold",
  "active": "cVq",
  "empty": "cVZ",
  "align-checkbox": "cVR"
};
