export default {
  "option": "cgw",
  "account-description": "cgN",
  "account-identification": "cgv",
  "account-avatar": "cgo",
  "account-name": "cgn body-2",
  "account-balance": "cgi body-2",
  "external-accounts-disclaimer": "cgA",
  "status": "cgY body-2",
  "failed-connection": "cgh"
};
