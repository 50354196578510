export default {
  "custom-period-wrapper": "cCK",
  "fadeIn": "cCG",
  "custom-period-description": "cCr body-2",
  "header": "cCb",
  "arrow-left": "cCy",
  "body": "cCU",
  "period-wrapper": "cCj",
  "back-button": "cCW",
  "footer": "cCu"
};
