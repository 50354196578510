export default {
  "fullscreen-modal": "cDV",
  "creation-form-modal": "cDL",
  "links-container": "cDT",
  "link-field": "cDz",
  "link": "cDa",
  "add-link": "cDH",
  "remove-link": "cDO",
  "flex-row": "cDm",
  "flex-item": "cDp",
  "unit-trigger": "cDx",
  "dynamic-placeholders": "cDw"
};
