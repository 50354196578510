export default {
  "file-uploader": "cVz",
  "file-uploader-content": "cVa",
  "file-uploader-dropzone": "cVH",
  "fixedSize": "cVO",
  "two-column": "cVm",
  "file-uploader-label": "cVp body-1",
  "required-chip": "cVx",
  "animated-container": "cVw",
  "toggle-button": "cVN",
  "toggle-button-icon": "cVv"
};
