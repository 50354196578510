export default {
  "mandate-list-item": "ctH",
  "selected": "ctO",
  "status-avatar": "ctm",
  "infos": "ctp",
  "info-title": "ctx body-1",
  "info-title-suspended": "ctw",
  "info-title-status-suspended": "ctN",
  "info-mandate-count": "ctv body-2"
};
