export default {
  "wrapper": "csP",
  "header": "csl",
  "fadeIn": "csX",
  "header-title": "csC title-4",
  "transactions-container": "csk",
  "placeholder-title": "csJ",
  "transaction-item-list-container": "css",
  "transaction-item-list": "csF",
  "item": "csg"
};
