export default {
  "notifications-settings": "clZ",
  "opened": "clR",
  "icon": "clQ",
  "header": "cle",
  "header-toggle": "clB",
  "avatar-image": "clE",
  "title": "cld",
  "icon--right": "clI",
  "toggle-container": "clt",
  "toggle": "clM"
};
