export default {
  "sidebar": "cdw",
  "top-section": "cdN",
  "title": "cdv",
  "primary-action": "cdo",
  "edit-icon": "cdn",
  "close-icon": "cdi",
  "box-wrapper": "cdA",
  "bottom-action": "cdY",
  "related-invoices": "cdh",
  "related-invoices-title": "cdf mb-16 caption-bold",
  "box": "cdK",
  "box-element": "cdG",
  "cancel-text": "cdr",
  "box-header": "cdb",
  "row": "cdy",
  "greyed-out": "cdU",
  "struck-through": "cdj",
  "share-mandate-link": "cdW"
};
