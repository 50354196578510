export default {
  "donut-chart-container": "cCS",
  "donut-chart-wrapper": "cCc",
  "chart": "cCq",
  "donut-chart-legend-container": "cCZ caption",
  "donut-chart-legend-item-empty": "cCR",
  "donut-chart-legend-bullet-empty": "cCQ",
  "donut-chart-legend-bullet": "cCe",
  "donut-chart-legend-text-empty": "cCB",
  "donut-chart-legend-text": "cCE",
  "donut-chart-custom-label-uncategorized": "cCd",
  "custom-label-uncategorized-button": "cCI body-1",
  "donut-chart-legend": "cCt",
  "donut-chart-legend-item": "cCM",
  "donut-chart-legend-other-item": "cCP",
  "donut-chart-legend-uncategorized-item": "cCl",
  "donut-chart-legend-text-name": "cCX",
  "tooltip": "cCC"
};
