export default {
  "container": "cdZ",
  "form-container": "cdR",
  "title": "cdQ",
  "title-border": "cde",
  "tabs-container": "cdB",
  "preview-container": "cdE",
  "email-preview-container": "cdd",
  "close-button": "cdI btn btn--icon-only btn--tertiary btn--large"
};
