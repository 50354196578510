export default {
  "item": "cMT",
  "selected": "cMz",
  "hover-revised": "cMa",
  "asset-container": "cMH",
  "asset": "cMO",
  "revoked-icon": "cMm",
  "pending-icon": "cMp",
  "revoked": "cMx",
  "revised-revoke": "cMw",
  "pending": "cMN",
  "revised-pending": "cMv",
  "invitable": "cMo",
  "info": "cMn",
  "name": "cMi",
  "email": "cMA",
  "actions": "cMY",
  "invitable-member-delete": "cMh",
  "invitable-member-invite": "cMf"
};
