export default {
  "members-loading": "cPI",
  "members-loading--ff": "cPt",
  "header": "cPM",
  "header-search-bar": "cPP",
  "body": "cPl",
  "members": "cPX",
  "placeholder-container": "cPC",
  "icon": "cPk",
  "details": "cPJ",
  "member-loading": "cPs",
  "member-loading--no-ff": "cPF",
  "body-members": "cPg",
  "body-details": "cPD"
};
