export default {
  "row": "cVB",
  "cell": "cVE",
  "title": "cVd",
  "internal-note": "cVI",
  "unit": "cVt",
  "price": "cVM",
  "quick-actions": "cVP",
  "missing-details-container": "cVl",
  "missing-details-label": "cVX body-2",
  "warning-icon": "cVC",
  "empty": "cVk",
  "checkbox": "cVJ",
  "align-checkbox": "cVs"
};
