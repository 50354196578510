export default {
  "card": "cXz",
  "header": "cXa",
  "avatar": "cXH",
  "title": "cXO",
  "subtitle": "cXm",
  "label": "cXp",
  "item": "cXx body-2",
  "header-icon": "cXw",
  "mt-8": "cXN"
};
