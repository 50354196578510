export default {
  "bullet": "ckJ",
  "slice-0": "cks",
  "slice-1": "ckF",
  "slice-2": "ckg",
  "slice-3": "ckD",
  "slice-4": "ckV",
  "slice-5": "ckL",
  "slice-6": "ckT",
  "slice-other": "ckz",
  "slice-uncategorized": "cka",
  "label": "ckH",
  "ellipsis": "ckO",
  "rest": "ckm",
  "previous-date-range": "ckp caption"
};
