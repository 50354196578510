export default {
  "integration-item": "cQf",
  "icon-container": "cQK",
  "content": "cQG",
  "title-container": "cQr",
  "new-badge": "cQb",
  "title": "cQy",
  "description": "cQU",
  "no-attributes": "cQj",
  "connect-cta": "cQW"
};
