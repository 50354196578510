export default {
  "company-logo": "cPr",
  "list-item": "cPb",
  "link": "cPy",
  "title-wrapper": "cPU",
  "title": "cPj body-2",
  "subtitle": "cPW caption",
  "truncated-text": "cPu",
  "image-placeholder": "clS"
};
