export default {
  "fieldset": "cEm",
  "numbering-format": "cEp",
  "numbering-fields": "cEx",
  "input-settings": "cEw",
  "label-tooltip": "cEN",
  "tooltip-icon": "cEv",
  "next-invoice-number": "cEo",
  "spacing": "cEn"
};
