export default {
  "wrapper": "cBT",
  "mobile-mode": "cBz",
  "logo": "cBa",
  "address-form": "cBH",
  "button-container": "cBO",
  "address-form-header": "cBm",
  "address-form-content": "cBp",
  "address-form-address-search": "cBx",
  "ember-basic-dropdown": "cBw",
  "address-form-footer": "cBN",
  "submit-cta": "cBv",
  "form": "cBo",
  "city-section": "cBn",
  "title": "cBi",
  "mobile-title": "cBA"
};
