export default {
  "row": "cFv",
  "cell": "cFo body-1",
  "row-cancelled": "cFn",
  "cell-amount": "cFi",
  "empty": "cFA",
  "align-right": "cFY",
  "debitor-container": "cFh",
  "debitor-info": "cFf",
  "debitor-name": "cFK body-1",
  "with-sidebar": "cFG",
  "method-col": "cFr",
  "resource-number": "cFb caption",
  "resource-icon": "cFy",
  "avatar": "cFU"
};
