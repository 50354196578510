export default {
  "card": "cXX",
  "header": "cXC",
  "avatar": "cXk",
  "title": "cXJ",
  "subtitle": "cXs",
  "label": "cXF",
  "item": "cXg body-2",
  "header-icon": "cXD",
  "dropdown-menu": "cXV",
  "menu-item": "cXL body-2",
  "delete": "cXT"
};
