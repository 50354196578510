export default {
  "container": "cBR",
  "header": "cBQ",
  "content": "cBe",
  "info-list": "cBB",
  "info-label": "cBE body-2",
  "info-value": "cBd body-2",
  "provider": "cBI",
  "policy-container": "cBt",
  "policy-label": "cBM body-2",
  "policy-number": "cBP body-2",
  "copy-to-clipboard": "cBl",
  "button": "cBX btn btn--primary btn--stretch btn--large"
};
