export default {
  "container": "cIg",
  "mapped": "cID",
  "header": "cIV",
  "title": "cIL caption",
  "select-option-label": "cIT",
  "content": "cIz",
  "item": "cIa body-2",
  "empty": "cIH"
};
