export default {
  "body": "cMK",
  "members": "cMG",
  "members-results": "cMr",
  "members-list": "cMb",
  "members-title": "cMy caption-bold",
  "member": "cMU",
  "empty-illustration": "cMj",
  "empty-title": "cMW title-3",
  "empty-description": "cMu body-2",
  "invitable-members": "cPS",
  "details": "cPc",
  "pagination-footer": "cPq"
};
