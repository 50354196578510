export default {
  "wrapper": "cJp",
  "header": "cJx",
  "fadeIn": "cJw",
  "header-content": "cJN",
  "with-cta": "cJv",
  "header-title": "cJo title-4",
  "advanced-filters-trigger": "cJn",
  "filters-button": "cJi",
  "filters-button-upsell": "cJA",
  "filters-upsell-item": "cJY",
  "filters-badge": "cJh",
  "chart-container": "cJf",
  "chart-legend-container": "cJK mb-24",
  "chart-watermark-container": "cJG",
  "chart-watermark": "cJr",
  "placeholder-title": "cJb",
  "placeholder-subtitle": "cJy",
  "advanced-filters": "cJU"
};
