export default {
  "header": "cFF",
  "top-section": "cFg",
  "close": "cFD btn btn--icon-only btn--tertiary",
  "container": "cFV",
  "picto": "cFL",
  "details": "cFT",
  "expiration-date": "cFz",
  "payment-method": "cFa"
};
