export default {
  "header": "cdT",
  "center": "cdz",
  "header-placeholder": "cda",
  "placeholder": "cdH",
  "name-block": "cdO",
  "block": "cdm",
  "detail-block": "cdp",
  "border-top": "cdx"
};
