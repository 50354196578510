export default {
  "wrapper": "cep",
  "content": "cex",
  "back": "cew btn btn--tertiary",
  "heading": "ceN",
  "heading-content": "cev",
  "logo": "ceo",
  "description": "cen body-2",
  "cta": "cei"
};
