export default {
  "name-wrapper": "cFu",
  "preset-name": "cgS",
  "invoices-entry-period-container": "cgc",
  "transactions-entry-period-container": "cgq",
  "period-wrapper": "cgZ",
  "period-btn": "cgR",
  "cancel-placeholder": "cgQ",
  "period-cancel-btn": "cge",
  "transactions-entry-container": "cgB",
  "invoices-entry-container": "cgE",
  "dropdown": "cgd",
  "dropdown-opened": "cgI",
  "dropdown-closed": "cgt"
};
