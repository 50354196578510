export default {
  "section-item": "ckc",
  "sm": "ckq",
  "xs": "ckZ",
  "section-item-label": "ckR body-2",
  "section-item-value": "ckQ body-2",
  "positive": "cke",
  "negative": "ckB",
  "hidden": "ckE",
  "section-item-caption": "ckd caption"
};
