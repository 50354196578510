export default {
  "sidebar-section": "ceA body-2",
  "sidebar-section-header": "ceY body-1 mb-4",
  "providers-logos": "ceh",
  "risks": "cef",
  "tag": "ceK tag",
  "external-link-icon": "ceG",
  "disclaimer": "cer",
  "divider": "ceb"
};
