export default {
  "bucket-item": "cQO",
  "button": "cQm",
  "content": "cQp",
  "bucket-icon": "cQx",
  "bucket-title-container": "cQw",
  "bucket-new-badge": "cQN",
  "bucket-title": "cQv",
  "arrow-icon": "cQo"
};
