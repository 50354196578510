export default {
  "row": "clG",
  "terminated": "clr",
  "status-avatar": "clb",
  "amount": "cly body-1",
  "item-info": "clU",
  "date-cell": "clj",
  "cell": "clW body-2",
  "status-icon": "clu",
  "amount-cell": "cXS",
  "item-name-cell": "cXc",
  "item-name-container": "cXq"
};
