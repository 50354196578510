export default {
  "wrapper": "cJQ",
  "cashflow-status": "cJe",
  "status": "cJB",
  "estimated": "cJE",
  "vat-due-amount": "cJd title-3",
  "vat-tooltip": "cJI",
  "icon-info": "cJt",
  "charts-wrapper": "cJM",
  "chart-error": "cJP",
  "chart-controls": "cJl",
  "amount-loading": "cJX"
};
