export default {
  "card": "cIU",
  "focus": "cIj",
  "title-format": "cIW",
  "header": "cIu",
  "headings": "ctS",
  "title": "ctc title-4",
  "main": "ctq title-3",
  "footer": "ctZ"
};
