export default {
  "details-box": "clv",
  "picto": "clo",
  "picto-status": "cln",
  "ghost": "cli",
  "primary-info": "clA",
  "date": "clY",
  "account-infobox": "clh",
  "declined-disclaimer": "clf",
  "nrc-download": "clK"
};
