export default {
  "y-axis-placeholder": "cXi",
  "y-axis-navigable": "cXA",
  "y-axis-tick": "cXY",
  "x-axis-placeholder": "cXh",
  "x-axis-navigable": "cXf",
  "x-axis-tick-wrapper": "cXK",
  "x-axis-tick": "cXG",
  "chart-loading-renderer": "cXr"
};
