export default {
  "header-cell": "cdu",
  "empty": "cIS",
  "header-content": "cIc caption-bold",
  "active": "cIq",
  "align-right": "cIZ",
  "first-col": "cIR",
  "mid-col": "cIQ",
  "status-col": "cIe"
};
