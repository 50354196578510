/* import __COLOCATED_TEMPLATE__ from './insurance-level-tile.hbs'; */
import Component from '@glimmer/component';

import { DEFAULT_CARD_DESIGNS, FLAT_ASSETS } from '@repo/cards-kit/constants';
import { BadgeStatus } from '@repo/design-system-kit';

interface InsuranceLevelTileSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceLevelTileComponent extends Component<InsuranceLevelTileSignature> {
  badgeStatus = BadgeStatus;

  get iconUri() {
    // @ts-expect-error
    return FLAT_ASSETS[DEFAULT_CARD_DESIGNS[this.args.cardLevel]];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::InsuranceLevelTile': typeof InsuranceLevelTileComponent;
  }
}
