export default {
  "navigation-dropdown": "cPa",
  "expanded": "cPH",
  "trigger": "cPO",
  "trigger-title-icon": "cPm",
  "collapsed": "cPp",
  "responsive": "cPx",
  "logo": "cPw",
  "active": "cPN",
  "trigger-title": "cPv",
  "content": "cPo",
  "content-container": "cPn",
  "dropdown-icon": "cPi",
  "link": "cPA"
};
