export default {
  "invitations-password-form": "cEE",
  "mobile-mode": "cEd",
  "logo": "cEI",
  "header": "cEt",
  "controls": "cEM",
  "footer": "cEP",
  "submit-cta": "cEl",
  "subtitle": "cEX"
};
